import { ActionsBuilder } from "../utils"
import AuthService from "../../services/auth"
import ConfigService from "../../services/config"
import ProfileService from "../../services/profile"
import namespace from "./namespace"

import QuiqChatLibrary from "../../lib/quiqchat"

const actionsBuilder = new ActionsBuilder(namespace)

export const login = actionsBuilder.createAction("login", (state, { user, profile, profileError }) => {
	state[namespace].isLoggedIn = true
	state[namespace].user = user
	state[namespace].profile = profile
	state[namespace].profileError = profileError
})

export const logout = actionsBuilder.createAction("logout", (state) => {
	state[namespace].isLoggedIn = false
	state[namespace].user = null
	state[namespace].profile = null
	state[namespace].profileError = false
})

export const UserActions = {
	login: () => async (dispatch) => {
		const user = await AuthService.getUserInfo()
		let profile = null,
			profileError = false

		try {
			profile = await ProfileService.fetchProfile()
		} catch (e) {
			console.log("Failed to fetch profile")
			profileError = true
		}

		dispatch(login({ user, profile, profileError }))

		return Boolean(user) && Boolean(profile)
	},

	logout: () => (dispatch) => {
		window.location.replace(ConfigService.config.site.myAccountDomain + "/logout")
		//clear the user's chat session
		QuiqChatLibrary.unload(true)
	},
}

export const actions = actionsBuilder.exportActions()
